// import axios from 'axios'
import * as XLSX from 'xlsx-js-style';
import http from '@/plugins/http.js' //axios实例化后引入取名http

export default {
    exportList: [],
    exportParam: {
        curr: 1,
        row: 100,
    },
    getExportList(param, api, tableHead, title, mergeField, cellColumns) {
        return new Promise((cback, reject) => {
            const fetchPage = (page) => {
                http.post(api, {
                    ...param,
                    curr: page,
                    row: this.exportParam.row,
                }, false).then(re => {
                    if (title == '拜访记录') {
                        re.data.list.map(i => {
                            i.site_name = i.site?.name,
                                i.province_name = i.province + i.city + i.district
                        })
                    }
                    this.exportList = [...this.exportList, ...re.data.list];

                    if (re.data.list.length > 0 && this.exportList.length !== re.data.count) {
                        fetchPage(page + 1);
                    } else {
                        cback();
                        this.exportToExcel(this.exportList, tableHead, title, mergeField, cellColumns);
                    }
                }).catch(error => {
                    console.error('请求失败:', error);
                    reject();
                });

            }

            fetchPage(this.exportParam.curr);
        })
    },
    /**
     * 
     * @param {*} exportData //导出数据
     * @param {*} tableHead //导出表头
     * @param {*} mergeField //合并的字段
     */
    exportToExcel(exportData, tableHead, title, mergeField, cellColumns) {
        const workbook = XLSX.utils.book_new();

        const headerMapping = tableHead;

        const mappedData = exportData.map(item => {
            return Object.keys(headerMapping).reduce((acc, key) => {
                acc[headerMapping[key]] = item[key];
                return acc;
            }, {});
        });

        const worksheet = XLSX.utils.json_to_sheet(mappedData);

        // 合并单元格逻辑
        const mergeCells = [];
        let headerRows = 1; // 表头行数，这里假设表头占据1行
        //是否合并单元格
        if (mergeField) {
            for (let i = 1; i < mappedData.length; i++) {
                const isSameOrder = mappedData[i][mergeField] === mappedData[i - 1][mergeField];


                if (isSameOrder) {
                    //行合并的条数
                    let cellCount = mappedData.filter(t => t[mergeField] == mappedData[i][mergeField]).length

                    const mergeColumns = cellColumns;

                    mergeColumns.forEach((col) => {
                        const colIndex = Object.values(headerMapping).indexOf(col); // 获取列的索引

                        if (colIndex !== -1) {
                            mergeCells.push({
                                s: { r: i - 1 + headerRows, c: colIndex }, // 起始位置，考虑表头
                                e: { r: i + cellCount + - 1, c: colIndex } // 结束位置，考虑表头
                            });
                        }
                    });

                    i = i + cellCount + - 1
                }
            }

            // 将合并单元格应用到工作表
            worksheet['!merges'] = mergeCells;
        }

        // 设置居中对齐和单元格样式
        const cellStyle = {
            alignment: {
                horizontal: "center",
                vertical: "center",
                wrapText: "true"
            },
            fill: {
                fgColor: {
                    rgb: "FFFFFF"
                }
            }, // 设置背景颜色为白色
            border: {
                top: {
                    style: "thin",
                    color: {
                        rgb: "000000"
                    }
                },
                bottom: {
                    style: "thin",
                    color: {
                        rgb: "000000"
                    }
                },
                left: {
                    style: "thin",
                    color: {
                        rgb: "000000"
                    }
                },
                right: {
                    style: "thin",
                    color: {
                        rgb: "000000"
                    }
                },
            }
        };

        // 应用样式到所有单元格
        Object.keys(worksheet).forEach(key => {
            if (key[0] !== '!') { // 跳过特殊属性
                worksheet[key].s = {
                    ...cellStyle
                }; // 确保每个单元格都有样式
            }
        });

        // 应用合并单元格的样式
        mergeCells.forEach(merge => {
            const startCell = worksheet[XLSX.utils.encode_cell({
                r: merge.s.r,
                c: merge.s.c
            })];
            if (startCell) {
                startCell.s = {
                    ...cellStyle
                }; // 确保合并单元格的起始单元格也有样式
            }
        });

        // 自动调整单元格宽度
        const colWidth = this.colWidth ? this.colWidth : [];
        if (!this.colWidth) {
            for (let i = 0; i <= Object.keys(tableHead).length; i++) {
                colWidth.push({ wch: 20 })
            }
        }

        worksheet['!cols'] = colWidth;

        //设置行高
        const rows = [{ hpx: 35 }]
        // for (let i = 1; i <= exportData.length; i++) {
        //     rows.push({ hpx: 25 })
        // }
        worksheet['!rows'] = rows; // 添加到sheet中


        XLSX.utils.book_append_sheet(workbook, worksheet, title);
        // 导出Excel文件
        XLSX.writeFile(workbook, `${title}.xlsx`);
    }
}