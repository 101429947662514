<!-- 拜访记录 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-input placeholder="客户名称" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:200px;" @input="curr=1;getList()"></el-input>
            <el-select v-model="select.site_id" placeholder="销售站点" :clearable="true" size="small" @change="curr=1;getList()">
                <el-option v-for="(item,index) in siteList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-date-picker style="width:230px" v-model="select.month" type="monthrange" range-separator="至" start-placeholder="月份" end-placeholder="筛选" format="yyyy-MM" value-format="yyyy-MM" @change="curr=1;getList()">
            </el-date-picker>
            <el-button type="primary" size="small" style="margin-left:auto;" @click="handleExport()">导出</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="site.name" label="站点" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user_type_name" label="分类" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="contacts" label="联系人" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="mobile" label="电话" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="" label="省市区" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{scope.row.province}}{{scope.row.city}}{{scope.row.district}}
                </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="count" label="累计拜访次数" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleShowDetail(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>
</div>
</template>

<script>
import useExportXls from '@/plugins/useExportXls';
import useExportHeader from '@/plugins/useExportHeader';
export default {
    data: function () {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            siteList: [],
            exportList: [],
            exportParam: {
                curr: 1,
                row: 100,
            },
        }
    },
    mounted: function () {
        this.getList();
        this.getAdmin()
    },
    methods: {
        getAdmin: function () {
            this.http.post('/sales.site/getList', {
                curr: 1,
                row: 99999
            }, false).then(re => {
                this.siteList = re.data.list;
            })
        },
        getList: function () {
            this.http.post('/admin.User/indexDay', {
                curr: this.curr,
                row: this.row,
                day_date: this.select.month,
                search: this.searchValue,
                site_id: this.select.site_id
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        handleShowDetail: function (row) {
            this.$router.push({
                path: '/VisitRecordDetail',
                query: {
                    id: row.id,
                    name: row.name,
                    day_date: this.select.month
                }
            })
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getList();
        },


        handleExport() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })

            let param = {
                curr: this.curr,
                row: this.row,
                day_date: this.select.month,
                search: this.searchValue,
                site_id: this.select.site_id
            };
            
            useExportXls.getExportList(param, '/admin.User/visitExport', useExportHeader.visitHeader, '拜访记录').then(()=>{
                console.log("111");
                loading.close();
            })
        }
    }
}
</script>
