//复购导出header
const visitHeader = {
    name: "名称",
    site_name: "站点",
    user_type_name: "分类",
    contacts: "联系人",
    mobile: "电话",
    province_name: "省市区",
    address: "地址",
    count: "累计拜访次数", 
}

export default {
    visitHeader,
};